import { Box } from "../../node_modules/@mui/material/index";
const Events = () => {
  return (
    <>
      <Box>
        <h1 style={{ fontFamily: "Arial, sans-serif", fontSize: "32px" }}>
          Evenimente pentru elevi
        </h1>
      </Box>
    </>
  );
};

export default Events;
