import React from "react";

const MarketTips = () => {
  return (
    <div>
      <h3>Советы по рынку</h3>
      <p>Рынок достигает равновесия, когда спрос равен предложению.</p>
      <p>Дефицит возникает, если спрос превышает предложение.</p>
      <p>Избыток возникает, если предложение превышает спрос.</p>
    </div>
  );
};

export default MarketTips;
